import React from "react";

const Cover = (props: any) => {
   const bg = require('./../../assets/images/Almac-Gateway-Banner.jpg');

   return (<div style={{ padding: '0 !important', width: '100vw', height: '300px', display: 'flex', justifyContent: "center", alignItems: "center", background: `url(${bg}) no-repeat`, backgroundSize: 'cover' }} >
      <h2>{props.app.name} </h2>
   </div>);
}
export default Cover;

import React from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            return (
              <li
                key={i}
                className="software-skill-inline"
                title={skills.skillName}
              >
                {!skills.icon && <i className={skills.fontAwesomeClassname}></i>}
                {skills.icon && <img className={skills.fontAwesomeClassname} src={skills.icon} style={{width:'40px'}} alt={skills.skillName}/>}
                <p>{skills.skillName}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

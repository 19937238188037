import React, { useEffect } from "react";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { StyleProvider } from "contexts/StyleContext";
import ScrollToTopButton from "../../containers/topbutton/Top";
import { useLocalStorage } from "hooks/useLocalStorage";
import './Payment.scss'

const Payment = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);

  useEffect(() => {

  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  const fillPrice = () => {
    // var item = rs.data.filter(ele => ele.id == $('#item').val())
    // var price = $('#quantity').val() * item[0].price
    // var totalPrice = new Intl.NumberFormat().format(price)
    // $('#totalPrice').html(totalPrice)
    // $('#price').val(price)
 }

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{ isDark: isDark, changeTheme: changeTheme }}>
        <>
          <Header />
          <div className='payment-main'>
          <h4>Hãy thực hiện gia hạn thời gian sử dụng chức năng trên ứng dụng!</h4>
            {/* <div id='formBuy'>
              <h4>Gia hạn thời gian sử dụng chức năng!</h4>
              <div title='upload'>
                <p>
                  <label>Chức năng: </label>
                  <select name='item' id='item' style={{float:'right'}}>
                  </select>
                </p>

                <p>
                  <label>Số lượng: </label>
                  <input type='number' name='quantity' id='quantity' value='1' onChange={fillPrice} style={{width:'64px', float:'right'}} />
                </p>
                <p>
                  <span>Total  =  </span>
                  <strong id="totalPrice" style={{float:'right'}}></strong>
                </p>
              </div>
              <input type='hidden' name='price' id='price' value='0' />
            </div> */}

            {/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="KA3G77RKGWUR6" />
              <input type="hidden" name="currency_code" value="USD" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" style={{ transform: 'scale(1.2)' }} name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
            </form> */}

            {/* <Paypal /> */}
          </div>
          <Footer />
          <ScrollToTopButton />
        </>
      </StyleProvider>
    </div>
  );
}

export default Payment;
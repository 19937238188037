import React from "react";
import "./Button.scss";

export default function Button({ text, className = '', href, newTab = false, classChildName = '' }) {
  return (text ? <div className={className}>
      <a className={classChildName !== '' ? classChildName : "main-button"} href={href} target={newTab ? "_blank" : undefined} rel="noreferrer">
        {text}
      </a>
    </div> : null
  );
}

import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { StyleProvider } from "contexts/StyleContext";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import ScrollToTopButton from "../../containers/topbutton/Top";
import { useLocalStorage } from "hooks/useLocalStorage";
import './Apps.scss'
import { Fade } from "react-reveal";
import { appmoresSection } from "../../portfolio";
import { useParams } from "react-router-dom";
import Cover from "components/cover";
import Button from "components/button/Button";
import { Item } from "services/ItemServices";
import config from "config/config";

const Apps = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const { appname } = useParams();
  const [app, setApp] = useState<any>([])
  const [items, setItems] = useState<any>([])
  const [detail, setDetail] = useState<any>([])
  // const [searchParams, setSearchParams] = useSearchParams();
  // useState(true);

  useEffect(() => {
    var _app: any = appmoresSection.experience.filter((card, i) => appname === card.role)
    setApp(_app[0]);

  }, [appname]);

  useLayoutEffect(() => {
    getItem()
  }, [])

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  const getItem = useCallback(async () => {
    if (appname === null) return null
    var _items: any = localStorage.getItem('items');
    var _data = JSON.parse(_items)
    // console.log( appname )
    if (!_data) {
      _data = await Item.get({ searchParams: {} })
      localStorage.setItem('items', _data)
    }
    // console.log(data)
    // var items: any = await Item.get({ searchParams: { slug: appname } })
    var items: any = _data.data.filter((ele: any, idx: number) => ele.slug === appname);
    console.log(items)
    if (items) {
      var data = items[0]
      setItems(data);

      var detail: any = data.detail.filter((ele: any, idx: number) => ele.lang === config.lang);
      detail = detail[0];
      setDetail(detail);

      // document.querySelectorAll('meta').getAttribute('')
      document.title = data.name + ' | ' + document.title
      // Cập nhật các thẻ meta trong JavaScript
      // document.querySelector('meta[name="description"]').setAttribute('content', detail.summary?.replace(/(<([^>]+)>)/gi, ''));
      // document.querySelector('meta[property$="title"]').setAttribute('content', data.name + ' | ' + document.querySelector('head title').innerHTML);
      // document.querySelector('meta[property$="description"]').setAttribute('content', detail.summary?.replace(/(<([^>]+)>)/gi, ''));
      // document.querySelector('meta[property$="url"]').setAttribute('content', window.location.href);
      // Thêm các thẻ meta mới nếu cần thiết

      document.querySelectorAll('head meta').forEach(meta => {
        if (meta.getAttribute('name') === 'title' || meta.getAttribute('property') === 'og:title' || meta.getAttribute('property') === 'twitter:title')
          meta.setAttribute('content', data.name + ' | ' + meta.getAttribute('content'))

        if (meta.getAttribute('name') === 'description' || meta.getAttribute('property') === 'og:description' || meta.getAttribute('property') === 'twitter:description')
          meta.setAttribute('content', detail?.summary?.replace(/(<([^>]+)>)/gi, ''))

        if (meta.getAttribute('property') === 'og:url' || meta.getAttribute('property') === 'twitter:url')
          meta.setAttribute('content', window.location.href)
      });

    }
  }, [appname])

  const Tabs = () => {
    return <div className="download">
      <h5>{items.name} includes an evaluation license key for a free 3-day trial.</h5>
      {/* <Button className='tab' href={'#'} newTab={false} text='Window' classChildName='tabButton' />
      <Button className='tab' href={'#'} newTab={false} text='Mac' classChildName='tabButton' />
      <br></br> */}
      {appname == 'pod-manager' ?
        <div className="button-appmore-div">
          <Button text={<><i className="fab fa-windows"></i> &nbsp;{'.exe'} </>} href={require('../../assets//apps/POD-Manager-1.0.5-Setup.exe')} />
          <Button text={<><i className="fab fa-apple"></i> &nbsp;{'.zip'} </>} href={require('../../assets/apps/POD-Manager-darwin-arm64-1.0.5.zip')} />
          <Button text={'Buy Now'} href={'/payment'} />
        </div>
        : <div className="button-appmore-div">
          <Button text={<><i className="fab fa-windows"></i> &nbsp;{'.exe'} </>} href={require('../../assets//apps/POD-Crawler-1.0.0-Setup.exe')} />
          {/* <Button text={<><i className="fab fa-apple"></i> &nbsp;{'.zip'} </>} href={require('../../assets/apps/POD-Manager-darwin-arm64-1.0.5.zip')} /> */}
          {/* <Button text={'Buy Now'} href={'/payment'} /> */}
        </div>
      }
    </div>
  }

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{ isDark: isDark, changeTheme: changeTheme }}>
        <>
          <Header />
          <Cover app={items} />
          <div className='apps-main'>
            <div id="appmores">
              <Fade bottom duration={1000} distance="40px">
                <div className="greet-main" id="greeting">
                  <div className="greeting-main">
                    <div className="greeting-text-div">
                      <div>
                        <h1 className={isDark ? "dark-mode greeting-text" : "greeting-text"} >
                          {" "}<img alt={app.company} src={app.companylogo} className="roundedimg" style={{ width: 42, marginRight: 10 }} />
                          {items.name}{" "}
                          {/* <span className="wave-emoji">{emoji("👋")}</span> */}
                        </h1>

                        <div className='apps-summary' dangerouslySetInnerHTML={{ __html: detail?.summary }} />

                        <Tabs />
                        {/* {app?.button ?
                          <div className="button-appmore-div">
                            {app?.button?.map((btn: any, idx: number) => btn?.html ? btn?.html : <Button text={btn?.title} href={btn.url} key={idx} />)}
                          </div>
                          : null} */}
                      </div>
                    </div>
                    {appname == 'pod-manager' ?
                    <div className="greeting-image-div" dangerouslySetInnerHTML={{ __html: `<iframe width="560" height="315" src="https://www.youtube.com/embed/rPz7e8LZ-Nc?si=rHmq3WU-64Y3_rh_&rel=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>` }} />
                    : ''}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          {detail?.content &&
            <div className='apps-main' id='apps-main' dangerouslySetInnerHTML={{ __html: detail?.content }} />
          }

          <div className='apps-main'><Tabs /></div>
          <Footer />
          <ScrollToTopButton />
        </>
      </StyleProvider>
    </div>
  );
};

export default Apps;
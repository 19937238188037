import axios from 'axios';

// class XhrHttps {
//   instance = null;

//   init = async (
//     fakeUserAgent: boolean = false,
//     config?: CreateAxiosDefaults<any>
//   ) => {
// let fakeUserAgent = true
const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
const Xhr = axios.create({ ...config, withCredentials: false });

//   axiosinstance.defaults.headers.common['User-Agent'] = userAgent;

Xhr.interceptors.request.use(
  (config: any) => {
    return config;
  },
  (error: any) => {
    return console.log(error);
  }
);

Xhr.interceptors.response.use(
  (response: { data: any }) => {
    // console.log(response.data)
    // console.log("Err res");
    return response.data;
  },
  (error: any) => {
    // console.log("interceptors.response error: " + (error));
    // console.log(error.toString() + " " + error.config.url);
    return JSON.stringify(error);
  }
);
//     return this.instance;
//   };
// }

export default Xhr 

import React, { useCallback, useEffect, useState } from "react";
import "./App.scss";
import { RouterProvider } from 'react-router-dom';

import { router } from "router/router";


const App: React.FC = () => {
	
	return (<RouterProvider router={router} />)
	// return (
	// 	<BrowserRouter>
	// 		<Routes>
	// 			<Route path="/" element={<Main />} />
	// 			<Route path="Apps" element={<Apps />} />
	// 			<Route path="Payment" element={<Payment />} />
	// 		</Routes>
	// 	</BrowserRouter>
	// );
}
// const App: React.FC = () => {
//   return (
//     <div>
//       <Main />
//     </div>
//   );
// }

export default App;

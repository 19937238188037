// import { configs } from "../config";
import config from 'config/config';
import Xhr from './XhrHttps';
class ItemServices {
  domain = config.domain + 'item';
  add = async (data: any) => {
    let url = `${this.domain}/add`;
    // console.log('---Regis---');
    // console.log(data)
    let item = await Xhr.post(url, data);
    return item;
  };

  get = async (data: any = null) => {
    // console.log(data)
    let url = `${this.domain}/search`;
    // await XhrHttps.init(false, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'X-Custom-Header, Upgrade-Insecure-Requests'} });
    // console.log(data)
    let items = await Xhr.post(url, data);
    return items;
  };
}
export const Item = new ItemServices();
